import React from 'react'
import mixpanel from 'mixpanel-browser'
import { MixpanelContext } from './src/tracking'

export const wrapRootElement = ({ element }) => {
  const mixpanelToken = process.env.GATSBY_MIXPANEL_TOKEN
  if (!mixpanelToken) {
    throw new Error('GATSBY_MIXPANEL_TOKEN must be set')
  }
  // // defaults: https://github.com/mixpanel/mixpanel-js/blob/8b2e1f7b/src/mixpanel-core.js#L87-L110
  mixpanel.init(mixpanelToken)
  if (process.env.NODE_ENV !== "production") {
    mixpanel.disable();
  }
  return (
    <MixpanelContext.Provider value={mixpanel}>
      {element}
    </MixpanelContext.Provider>
  )
}
